import { render, staticRenderFns } from "./homecontent.vue?vue&type=template&id=63724e16&scoped=true&"
import script from "./homecontent.vue?vue&type=script&lang=js&"
export * from "./homecontent.vue?vue&type=script&lang=js&"
import style0 from "./homecontent.vue?vue&type=style&index=0&id=63724e16&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63724e16",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCol,VDivider,VFlex,VHover,VResponsive,VRow,VSheet,VTooltip})
